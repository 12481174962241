import React, { useEffect, useState } from "react";
import Styles from "../../Categories/Categories.module.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { DOMAIN } from '../../../../constants/envUrl'
import noPeopleImg from '../../../assets/Images/2.png'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';



import {
  UpdateProviderCategoriesListOptions,
  getAllCategories,
  getProviderCategoriesList,
  getProviderCategoriesListOptions,
  addMainCategoriesToProductCategory,
  gellAllCategoriesByProductCat,
  uploadFile,
  createProductCategory,
  getMainCategories,
  updateMainCategory,
  createMainCategory
} from "../../../../config/api";
import { useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Categories = ({ langConfig, langData }) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [mainCategories, settMainCategories] = useState([])
  const [editingElement, setEditingElement] = useState(null)
  const [editableValue, setEditableValue] = useState('')
  const [newMainCategory, setNewMainCategory] = useState({
    name: "",
    imageLink: "",
    iconLink: "",
    description: ""
  })

  console.log('editingElement: ', editingElement)

  const [isAddCategory, setIsAddCategory] = useState(false)
  
  const navigate = useNavigate();


  const formNewMainCategory = async (key, event) => {

    if (key === 'imageLink' || key === 'iconLink') {

      const file = event.target.files[0]

      try {
        const formData = new FormData();
        formData.append('image', file); // Assuming the file is an instance of File or Blob

        const response = await axios.post(uploadFile(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status !== 200) return
        const data = response.data;
        setNewMainCategory(prevProdCat => ({ ...prevProdCat, [key]: `/server-images/${data}` }))

        // `https://yyy.codersuccess.com/server-images/${data}`
        console.log("formNewMainCategory: ", data)
      } catch (err) {
        console.log(err)
      }

    } else {
      setNewMainCategory(prevProdCat => ({ ...prevProdCat, [key]: event.target.value }))
    }
  }

  const createNeMainCategory = async () => {
    try {
      const { data } = await axios.post(createMainCategory(), newMainCategory)
      if (data.result) {
        handleGetMainCategories()
      }
      setIsAddCategory(false)
      // console.log('createNeMainCategory', response.data)
    } catch (error) {
      console.log(error)
    }

  }

  console.log('newMainCategory: ', newMainCategory)


  const editElmentHandler = (id) => {
    // const input = document.querySelector(`#input-${id}`)
    // input.focus()
    setEditingElement(id)
    console.log('editElmentHandler: ', id)
  }
  const editMainCategory = (id) => {
    navigate(`/edit-main-category/${id}`);
  };

  const uploadImage = async (event, obj, key) => {

    console.log('uploadImage: obj, key', obj, key)

    const file = event.target.files[0]

    try {
      const formData = new FormData();
      formData.append('image', file); // Assuming the file is an instance of File or Blob

      console.log('uploadImage: ', formData)
      const response = await axios.post(uploadFile(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const data = response.data;
      if (response.status !== 200) return
      setEditableValue(`/server-images/${data}`)

      const { description, categoryImage, categoryIcon, niceName, id } = obj;

      const updatedObject = {
        id: id,
        name: niceName,
        imageLink: key === 'imageLink' ? `/server-images/${data}` : categoryImage,
        iconLink: key === 'iconLink' ? `/server-images/${data}` : categoryIcon,
        description: description
      }


      const responseUpdate = await axios.post(updateMainCategory(), updatedObject)

      console.log('response.result', response)
      if (responseUpdate.data.result) {
        setEditingElement(null)
        setEditableValue('')
        handleGetMainCategories()
      }

      console.log('uploadImage: ', data, response)
      return data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const deleteCategory = async (id) => {
    //Need to add real endpoint when have one
    // const response = await axios.post(deleteCategoryEndp(), id)
    // if (response.result) {
    //   alert()
    // } else {
    //   alert("Something went wrong while deleting category")
    // }

  }

  const updateMainCategoryHandler = async (obj, key) => {

    console.log('updateMainCategoryHandler: ', obj)
    const {
      description,
      categoryImage, categoryIcon, niceName, id } = obj;

    const updatedObject = {
      id: id,
      name: key === 'name' ? editableValue : niceName,
      imageLink: key === 'imageLink' ? editableValue : categoryImage,
      iconLink: key === 'iconLink' ? editableValue : categoryIcon,
      description: key === 'description' ? editableValue : description
    }

    try {
      //UNCOMMENT WHEN HAVE REAL ENDPOINT FOR MAIN CATEGORIES UPDATE

      const response = await axios.post(updateMainCategory(), updatedObject)

      console.log('response.result', response)
      if (response.data.result) {
        setEditingElement(null)
        setEditableValue('')
        handleGetMainCategories()

      } else {
        alert('Error updating main categories')
      }

    } catch (error) {
      console.log('Error updating main categories:', error)
      alert('Error updating main categories:', error)
    }

    // const newObj = {...obj, [key] : editableValue}
    console.log('updateProductCat: ', updatedObject)
  }



  const fetchCategoriesList = async () => {
    try {
      setCategoriesLoading(true);
      // const response = await axios.get(getProviderCategoriesList(id));
      const response = await axios.get(getProviderCategoriesListOptions());
      const data = response.data;
      setCategoriesList(data.data);
      // console.log("categoriesList", data.data);
      setCategoriesLoading(false);
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };


  const UpdateCategoriesListOptions = async () => {
    try {
      // console.log("categoriesList send to server", categoriesList);
      setCategoriesLoading(true);
      const response = await axios.post(
        UpdateProviderCategoriesListOptions(),
        categoriesList
      );
      setCategoriesLoading(false);
      await fetchCategoriesList();
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  const handleGetMainCategories = async () => {
    try {
      const response = await axios.get(getMainCategories());
      const { data } = response;
      if (data.result) {
        console.log('handleGetMainCategories: ', data.mainCategoriesList)
        settMainCategories(data.mainCategoriesList)
      }

    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {


    handleGetMainCategories()

    return () => {

    }
  }, [])
  const addMainCategory = ()=>{
    navigate('/add-main-category');
  }


  return (
    <div className={Styles.containerUser}>
     {/*<div className={Styles.headingUser}>
        <h1>
          {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.subTabName}

        </h1>
  </div>*/}

      <div className={Styles.filterBox}>
        <div className="d-flex justify-content-end align-items-center">
        </div>
      </div>
      <div                            style={{display: 'flex', justifyContent: 'left'}}
>
                          <button
                            onClick={() => {
                              addMainCategory()
                          
                            }
                            }
                            className={`${Styles.btnStyle}`}
                          >
                          הוסף קטגוריה ראשית
                          </button>

                      </div>
      <div className="card mt-5">
        <div className="card-header">
          <h2 className="card-title">
            {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.subTabName}
          </h2>
        </div>
        <div className="card-body">
          <div className={Styles.tableResponsive}>
            <table className={`${Styles.table} ${Styles.tableStriped}`}>
              <thead>
                <tr>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.table.catName}
                  </th>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.table.catDesc}
                  </th>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.table.catIcon}
                  </th>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.table.catImage}
                  </th>
                  <th></th>
                {/*} { <th>
                    {/* {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.table.catDate} 
                  </th>*/}
           </tr>
              </thead>
              {!categoriesLoading ? (
                <tbody>
                  {mainCategories?.length > 0 ? (
                    mainCategories?.map((item, index) => (
                      <>
                        <tr key={index}>
                          {/* <td>

                          </td> */}
                          <td>

                            {editingElement == `${index}0` ? (
                              <>
                                <input
                                  onChange={(e) => setEditableValue(e.target.value)}
                                  id={`input-${index}0`} type="text" defaultValue={item.categoryAdminName} />
                                <button onClick={() => updateMainCategoryHandler(item, 'name')}>Save</button>
                              </>
                            ) : (
                              <>
                                <span>
                                  {item.categoryAdminName}
                                </span>
                              </>
                            )}
                          </td>
                          <td>
                            {editingElement == `${index}1` ? (
                              <>
                                <input
                                  onChange={(e) => setEditableValue(e.target.value)}
                                  id={`input-${index}1`} type="text" defaultValue={item.description} />
                                <button onClick={() => updateMainCategoryHandler(item, 'description')}>Save</button>
                              </>
                            ) : (
                              <>
                                <span>
                                  {item.description}
                                </span>
                              </>
                            )}
                          </td>
                          <td>
                            <span className={Styles.tableImageCover}>
                              <img className={`${Styles.tableImage}`} src={`${DOMAIN}${item.categoryIcon}`} alt="" />
                            </span>

                            <label>
                              <input
                                style={{ width: 0, position: "absolute", left: "100%" }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => uploadImage(e, item, 'iconLink')}
                                id={`input-${index}2`}
                              />
                            </label>

                          </td>
                          <td>
                            <img className={`${Styles.tableImage} img-people`} src={`${DOMAIN}${item.categoryImage}`} alt="" />
                            <img className={`${Styles.tableImage} img-no-people`} src={noPeopleImg} alt="" />

                            <label>
                              <input
                                style={{ width: 0, position: "absolute", left: "100%" }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => uploadImage(e, item, 'imageLink')}
                                id={`input-${index}3`}
                              />
                            </label>

                          </td>
                          <td>
                            <Button
                              onClick={() => editMainCategory(item.id)}
                            >
                              עריכה
                            </Button>
                          </td>
                         {/*  <td>
                            <button
                              className={`${Styles.btnStyle}`}

                              onClick={() => deleteCategory(item.id)}>
                              {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.table.deleteBtn}
                            </button> 
                          </td>*/}
                        </tr>
                      </>


                    ))
                  ) : (
                    <tr>
                      <td colSpan={"3"} className="text-center py-5">
                        אין תוצאות
                      </td>
                    </tr>
                  )}

                  {isAddCategory && (
                    <tr
                    >
                      {/* <td>

                      </td> */}
                      <td>

                        <input
                          onChange={(e) => formNewMainCategory('name', e)}
                          type="text" />

                      </td>
                      <td>
                        <input
                          onChange={(e) => formNewMainCategory('description', e)}
                          type="text"
                        />
                      </td>
                      <td>
                        {newMainCategory.iconLink ? (
                          <img className={`${Styles.tableImage}`} src={`${DOMAIN}${newMainCategory.iconLink}`} alt="" />

                        ) : (
                          <label>
                            <input
                              style={{ width: 0, position: "absolute", left: "100%" }}
                              type="file"
                              accept="image/*"
                              onChange={(e) => formNewMainCategory('iconLink', e)}
                            />
                            <span
                              style={{ display: "block", width: "max-content" }}
                              className={`${Styles.btnStyle}`}

                            >
                              {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.table.uploadIcon}
                            </span>
                          </label>
                        )}


                      </td>
                      <td>
                        {
                          newMainCategory.imageLink ? (
                            <>
                              <img className={`${Styles.tableImage} img-people`} src={`${DOMAIN}${newMainCategory.imageLink}`} alt="" />
                              <img className={`${Styles.tableImage} img-no-people`} src={noPeopleImg} alt="" />
                            </>

                          ) : (
                            <label>
                              <input
                                style={{ width: 0, position: "absolute", left: "100%" }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => formNewMainCategory('imageLink', e)}
                              />
                              <span
                                style={{ display: "block", width: "max-content" }}
                                className={`${Styles.btnStyle}`}

                              >
                                {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.table.uploadImage}
                              </span>
                            </label>
                          )
                        }

                      </td>
                      <td>
                      </td>
                      <td>
                        <button
                          onClick={createNeMainCategory}
                          className={`${Styles.btnStyle}`}
                        >
                          {langData[langConfig.userType][langConfig.lang].tab2.mainCategories.table.saveCategory}
                        </button>
                      </td>
                    </tr>)}
                  <tr>
                    {/* <td></td> */}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>

                    </td>
                    {/* <td>

                    </td> */}
                    <td>
                
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan={"3"}
                      className="text-center"
                      style={{
                        height: "200px",
                      }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Categories;
