import React, { useState } from "react";
import Styles from "./table.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import { Button } from "react-bootstrap";
import { addNewProvider } from "../../../config/api";
import EditProvider from "../../pages/edit-provider/EditProvider";


const dubbyTemplateProvider = [
  {
    "username": "user name",
    // "email": null,
    "password": null,
    // "firstName": "",
    // "lastName": "",
    "phoneNumber": null,
    "roleId": "Provider",
    // "creationDate": null,
    // "companyName": null,
    // "birthdate": null,
    // "companyName": "",
    // "bankAccountId": "Idxxxxxx",
    // "birthdate": null,
    // "entranceNumber": null,
    // "apartmentNumber": null,
    // "houseNumber": null,
    // "city": null,
    // "street": null,

  }
]


const TableProfiles = ({
  data,
  tableLoading,
  heading,
  type,
  updateUser,
  fetchData,
  isAdmin,
  langData,
  langConfig
}) => {
  const navigate = useNavigate();
  const [editingElement, setEditingElement] = useState(null)
  const [editableValue, setEditableValue] = useState('')
  const [isAddProvider, setIsAddProvider] = useState(false)
  const [newProvider, setNewProvider] = useState(dubbyTemplateProvider)
  console.log('table_data:', data)
  const updateProviderData = async (obj, key) => {

    console.log('updateProviderData: ', obj)
    const {
      description,
      categoryImage, categoryIcon, niceName, id } = obj;

    const updatedObject = {
      id: id,
      name: key === 'name' ? editableValue : niceName,
      imageLink: key === 'imageLink' ? editableValue : categoryImage,
      iconLink: key === 'iconLink' ? editableValue : categoryIcon,
      description: key === 'description' ? editableValue : description
    }

    try {

      const response = await axios.post('fakeApi', updatedObject)

      console.log('response.result', response)
      if (response.data.result) {
        setEditingElement(null)
        setEditableValue('')
        // handleGetMainCategories()
        fetchData();
      } else {
        alert('Error updating updateProviderData')
      }

    } catch (error) {
      console.log('Error updating updateProviderData:', error)
      alert('Error updating updateProviderData:', error)
    }

    // const newObj = {...obj, [key] : editableValue}
    console.log('updateProductCat: ', updatedObject)
  }

  const mapProvider = (id, provider) => {
    console.log('check idd', id)
    const fullName = provider.firstName + " " + provider.lastName
    navigate(`/categories/${id}`, { state: { fullName } });
  };

  const EditProvider = (id) => {
    navigate(`/edit-provider/${id}`);
  };


  const importProducts = (id, provider) => {
    console.log('check idd', id)
    const fullName = provider.firstName + " " + provider.lastName
    navigate(`/import-products/${id}`, { state: { fullName } });
  };

  const editElmentHandler = (id) => {
    setEditingElement(id)
    console.log('editElmentHandler: ', id)
  }

  // const handleAddProvider = (e, type) => {
  //   setNewProvider((prevState) => {
  //     const updatedProvider = [...prevState];
  //     updatedProvider[0] = {
  //       ...updatedProvider[0],
  //       [type]: e.target.value,
  //     };
  //     return updatedProvider;
  //   });
  // };

  function formatDate(date, format) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();
  
    // Replace placeholders in the format string
    format = format.replace('DD', String(day).padStart(2, '0'));
    format = format.replace('MM', String(month).padStart(2, '0'));
    format = format.replace('YYYY', year);
  
    return format;
  }
  // const handleAddProvider = (e, type) => {
  //   setNewProvider((prevState) => {
  //     const updatedProvider = [...prevState];
  //     updatedProvider[0] = {
  //       ...updatedProvider[0],
  //       [type]: e.target.value,
  //       username:
  //         type === "firstName" || type === "lastName"
  //           ? `${updatedProvider[0].firstName} ${updatedProvider[0].lastName}`
  //           : updatedProvider[0].username,
  //     };
  //     return updatedProvider;
  //   });
  // };

  const handleAddProvider = (e, type) => {
    setNewProvider((prevState) => {
      const updatedProvider = { ...prevState[0] }; // Copy the current provider object
  
      if (type === "firstName" || type === "lastName") {
        // Update firstName and lastName
        updatedProvider[type] = e.target.value;
        // Recreate the username
        updatedProvider.username = `${updatedProvider.firstName ?? ''} ${updatedProvider.lastName ?? ''}`;
      } else if (type === "birthdate") {
        // Handle birthdate
        const originalDate = e.target.value;
        const parsedDate = new Date(originalDate);
        const formattedDate = formatDate(parsedDate, 'DD-MM-YYYY'); // Customize the desired format
        updatedProvider.birthdate = formattedDate;
      } else {
        // For other types, update the value as is
        updatedProvider[type] = e.target.value;
      }
  
      return [updatedProvider]; // Return as an array
    });
  };

  const addProvider = async () => {
    navigate('/add-provider')
   {/* console.log('addProvider: ', newProvider)
    // newProvider[0].creationDate = new Date().toISOString()

    try {
      const response = await axios.post(addNewProvider(), newProvider[0])
      console.log('response.result', response)
      if (response.data.result) {
        fetchData()
        setIsAddProvider(false)
      } else if (response.data.result === false){
        alert(response.data.message);
      } else {
        alert("כל השדות חובה!");
      }

    } catch (error) {
      console.log(error);
      alert("כל השדות חובה!");
    }*/}
  }

  console.log('type: ', type)

  return (
    <>
{isAdmin && (
  <div style={{ textAlign: 'left' }}>
    <Button className="m-3" onClick={addProvider}>
      {isAddProvider ? (
        langData[langConfig.userType][langConfig.lang].tab1.saveProvider
      ) : (
        langData[langConfig.userType][langConfig.lang].tab1.addProvider
      )}
    </Button>
  </div>
)}


    <div className="card mt-5">
      <div className="card-header">
        <h2 className="card-title">ספקים</h2>
      </div>
      <div className="card-body">
        <div className={Styles.tableResponsive}>
          <table className={`${Styles.table} ${Styles.tableStriped}`}>
            <thead>
              <tr>
               {/* <th className={Styles.horizantal}>
                  {langData[langConfig.userType][langConfig.lang].tab1.table.action}

      </th>*/}
                <th  className={Styles.horizantal}>
                  {langData[langConfig.userType][langConfig.lang].tab1.table.fname}
                </th>
                <th>
                  {langData[langConfig.userType][langConfig.lang].tab1.table.lname}
                </th>
                <th>
                  {langData[langConfig.userType][langConfig.lang].tab1.table.email}
                </th>
                <th>
                  {langData[langConfig.userType][langConfig.lang].tab1.table.phone}
                </th>
                {!isAdmin && (
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab1.table.birthday}
                  </th>
                )}
                {/* <th>
                  {langData[langConfig.userType][langConfig.lang].tab1.table.role}
                </th> */}
                <th>
                  {langData[langConfig.userType][langConfig.lang].tab1.table.companyName}
                </th>
                {/*<th>
                  {langData[langConfig.userType][langConfig.lang].tab1.table.bankAccId}
              </th>*/}
                <th>
                  {langData[langConfig.userType][langConfig.lang].tab1.table.creationDate}
                </th>
                {type === "User" ? (
                  <>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].tab1.table.numOfOrders}
                    </th>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].tab1.table.totalOrders}
                    </th>
                  </>
                ) : (
                  <>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].tab1.table.numOfProducts}
                    </th>
                    <th>
                      הזמנות פתוחות
                 </th>
                    <th>
                      הזמנות בחודש הנוכחי
               </th>
                    <th></th>
                  </>
                )}
              </tr>
            </thead>
            {!tableLoading ? (
              <tbody>
                {data?.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      {/* {console.log('item', item)} */}
                     {/* <td className="d-flex justify-content-between align-items-center">
                       {/* <label className={Styles.switch}>
                          <input
                            type="checkbox"
                            name="switch"
                            checked={item.isEnable}
                            onChange={() => {
                              updateUser(item.id, !item.isEnable);
                            }}
                          />
                          <span
                            className={`${Styles.slider} ${Styles.round}`}
                          ></span>
                      </label>
                         {/* {isAdmin && (
                          <button style={{
                            margin: '10px',
                            width: '90px',
                          }}
                            className="btn btn-primary ml-1 actionbutton"
                            onClick={() => mapProvider(item.id, item)}
                          >
                            קטגוריות ספק
                          </button>
                        )}
                        {isAdmin && (
                          <button style={{
                            margin: '10px',
                            width: '90px',
                          }}
                            className="btn btn-primary ml-1 actionbutton"
                            onClick={() => importProducts(item.id, item)}
                          >
יבוא מוצרים
                          </button>
                        )}
                      </td>*/}
                      <td>
                 
                          <>
                            <span>
                              {item.firstName}
                            </span>
                          </>
                      </td>
                      <td>
                        {editingElement == `${index}lastName` ? (
                          <>
                            <input
                              onChange={(e) => setEditableValue(e.target.value)}
                              id={`input-${index}lastName`} type="text" defaultValue={item.lastName} />
                            <button onClick={() => updateProviderData(item, 'lastName')}>Save</button>
                          </>
                        ) : (
                          <>
                            <span>
                              {item.lastName}
                            </span>
                          </>
                        )}
                        { }
                      </td>
                      <td>{item.email}</td>
                      <td>{item.phoneNumber}</td>
                      {!isAdmin && (
                        <td>{item.dob?.split('T')[0]}</td>
                      )}
                      {/* <td>{item.role}</td> */}
                      <td>{item.companyName}</td>
                    {/*  <td>{item.bankAccountId}</td>*/}
                    
                      <td>{item.сreationDate ? item.сreationDate.split('T')[0].split('-').reverse().join('/') : ''}</td>
                      {type === "User" ? (
                        <>
                        {console.log('user_item', item)}
                          <td>{item.numbersOfOrdersOfBuyer ?? 0}</td>
                          <td>{item.sumOfPaidOrders ?? 0}</td>
                        </>
                      ) : (
                        <>
                        {console.log('debugNumberOfProducts provider_item', item)}
                        
                          <td>{item.numberOfProducts ?? 0}</td>
                          <td>{item.numbersOfOpenOrdersOfProvider}</td>
                          <td>
                            {item.numberOfOrdersInCurrentMonth}
                           {/* {typeof item.numberOfProducts === 'number' || typeof item.numbersOfOrdersOfProvider === 'number'
                              ? (item.numberOfProducts || 0) + (item.numbersOfOrdersOfProvider || 0)
                              : 0}*/}
                          </td>
                          <td>
                            <Button
                              onClick={() => EditProvider(item.id)}
                            >
                              עריכה
                            </Button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={type === "User" ? "12" : "13"}
                      className="text-center py-5"
                    >
                      אין תוצאות
                    </td>
                  </tr>
                )}

                {isAddProvider && (
                  newProvider.map((item, index) => (
                    <tr key={index}>
                      <td></td>
                      <td>
                        <label>
                        {langData[langConfig.userType][langConfig.lang].tab1.table.fname}
                          <input
                            onChange={(e) => handleAddProvider(e, 'firstName')}
                            type="text" defaultValue={newProvider.firstName} />
                        </label>
                      </td>
                      <td>
                        <label>
                        {langData[langConfig.userType][langConfig.lang].tab1.table.lname}
                          <input
                            onChange={(e) => handleAddProvider(e, 'lastName')}
                            type="text" defaultValue={newProvider.lastName} />
                        </label>
                      </td>
                      <td>
                        <label>
                        {langData[langConfig.userType][langConfig.lang].tab1.table.email}
                          <input
                            onChange={(e) => handleAddProvider(e, 'email')}
                            type="email" defaultValue={newProvider.email} />
                        </label>
                      </td>
                      <td>
                        <label>
                        {langData[langConfig.userType][langConfig.lang].tab1.table.companyName}
                          <input
                            onChange={(e) => handleAddProvider(e, 'companyName')}
                            type="text" defaultValue={newProvider.companyName} />
                        </label>
                      </td>
                      <td>
                        <label>
                        {langData[langConfig.userType][langConfig.lang].tab1.table.birthday}
                          <input
                            onChange={(e) => handleAddProvider(e, 'birthdate')}
                            type="date" defaultValue={newProvider.birthdate} />
                        </label>
                      </td>
                      <td>
                        <label>
                        {langData[langConfig.userType][langConfig.lang].tab1.table.phone}
                          <input
                            onChange={(e) => handleAddProvider(e, 'phoneNumber')}
                            type="text" defaultValue={newProvider.phoneNumber} />
                        </label>
                      </td>

                      <td>
                        <label>
                        {langData[langConfig.userType][langConfig.lang].tab1.table.password}
                          <input
                            onChange={(e) => handleAddProvider(e, 'password')}
                            type="text" defaultValue={newProvider.password} />
                        </label>
                      </td>
               

                    </tr>
                  ))
                )}
                <tr>

                </tr>
          

                     

              </tbody>
            ) : (
              <tbody>
                <>
                  <tr>
                    <td
                      colSpan={type === "User" ? "12" : "13"}
                      className="text-center"
                      style={{
                        height: "200px",
                      }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </td>
                  </tr>
                </>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
    </>
  );
};

export default TableProfiles;
