import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Styles from '../../components/Tables/table.module.css';

import axios from 'axios';
import { uploadFile, UpdateCreateProduct, GetExperienceOptionsById } from '../../../config/api';
import { DOMAIN } from '../../../constants/envUrl';
import DeleteIcon from '@mui/icons-material/Delete';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Box, Typography, IconButton
} from '@mui/material';

function stringifyArrays(obj) {
  const newObj = { ...obj };

  function recurse(current) {
    for (const key in current) {
      if (Array.isArray(current[key]) && key !== 'categories') {
        current[key] = JSON.stringify(current[key]);
      } else if (typeof current[key] === 'object' && current[key] !== null) {
        recurse(current[key]);
      }
    }
  }

  recurse(newObj);
  return newObj;
}

function EditExperience({ userId, langData, langConfig }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { product, mainCategories } = location.state;
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [productData, setProductData] = useState({
    ...product,
    options: product.options || []  // Ensure options array is present even if empty
  });
  const [error, setError] = useState("");

  const handleInputEdit = (e, id, mainKeyName, name) => {
    let { value } = e.target;

    if (mainKeyName === 'categories') {
      setProductData({ ...productData, [mainKeyName]: `|${value}` });
    } else if (Array.isArray(productData[mainKeyName])) {
      const updatedArray = productData[mainKeyName]?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      });

      setProductData({
        ...productData,
        [mainKeyName]: updatedArray,
      });
    } else {
      setProductData({ ...productData, [mainKeyName]: value });
    }
  };

  const addImages = async (event) => {
    const files = Array.from(event.target.files);

    let imagesString = '';
    for (let i = 0; i < files.length; i++) {
      imagesString = await uploadImage(files[i], imagesString);
    }
    setProductData({ ...productData, images: imagesString });
  };

  async function uploadImage(file, imagesString) {
    try {
      const formData = new FormData();
      formData.append('image', file);
      const response = await axios.post(uploadFile(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status !== 200) return imagesString;
      const data = response.data;
      imagesString = imagesString === '' ? `/images/${data}` : imagesString.replace(/[\[\]]/g, '') + `,/images/${data}`;
      return imagesString;
    } catch (err) {
      console.log(err);
      return imagesString;
    }
  }

  const deleteImage = (index) => {
    const confirmed = window.confirm("Are you sure you want to remove this image?");
    if (confirmed) {
      const updatedImagesArr = productData.images.split(',').filter((item, i) => i !== index).join(',');
      setProductData({ ...productData, images: updatedImagesArr });
    }
  };

  const addItem = (mainKeyName) => {
    let updatedItem;

    if (mainKeyName === 'options') {
      updatedItem = {
        id: productData[mainKeyName].length,
        name: '',
        values: '',
      };
    } else if (mainKeyName === 'variations') {
      updatedItem = {
        id: productData[mainKeyName].length,
        name: '',
        price: '',
        discountPrice: '',
      };
    } else if (mainKeyName === 'deliveryTypes') {
      updatedItem = {
        id: productData[mainKeyName].length,
        name: '',
        price: '',
      };
    } else if (mainKeyName === 'expandedDescription') {
      updatedItem = {
        id: productData[mainKeyName].length,
        title: '',
        text: '',
      };
    }

    setProductData({
      ...productData,
      [mainKeyName]: [...productData[mainKeyName], updatedItem],
    });
  };

  const removeItem = (mainKeyName, id) => {
    const confirmed = window.confirm("Are you sure you want to remove this item?");
    if (confirmed) {
      const updatedItems = productData[mainKeyName].filter((item) => item.id !== id);
      setProductData({ ...productData, [mainKeyName]: updatedItems });
    }
  };

  const postProductUpdate = async () => {
    const updatedProductData = {
      ...productData,
      providerId: userId,
      price: +productData.price,
      discountPrice: +productData.discountPrice,
      categoryId: productData.categories.replace("|", ""),
    };
    const modifiedObject = stringifyArrays(updatedProductData);
    try {
      const response = await axios.post(UpdateCreateProduct(), modifiedObject);
      if (response.data.result) return navigate(-1);
      alert('Something went wrong editing the product');
    } catch (err) {
      console.log(err);
      alert('Something went wrong editing the product');
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      setError("");

      try {
        const response = await axios.get(GetExperienceOptionsById(), {
          params: { productId: product.id }, // Pass `experienceId` as query parameter

        })
        


        if (response.data.result && response.data.options) {
          // Populate options if the API returns valid data
          setProductData((prev) => ({ ...prev, options: response.data.options }));
        } else {
          // Handle cases where the API returns no options
          setError("No options found for this experience.");
        }
      } catch (err) {
        console.error("Error fetching options:", err);
        setError("Failed to fetch options. Please try again later.");
      } finally {
        setLoadingOptions(false);
      }
    };

    fetchOptions();
  }, [product.id]);

  return (
    <Box style={{ margin: '60px' }}>
      <form style={{ width: '90%' }}></form>
      <h3 style={{ fontSize: '36px', fontStyle: 'normal', fontWeight: '700', color: '#154360', marginTop: '5vh' }}>עריכת מוצר</h3>
      <Button style={{ width: '50px', position: 'absolute', right: '195vh', top: '30vh' }} className='btn-light btn-outline-dark mt-3'>
        <ArrowBackIosIcon onClick={() => navigate(-1)} />
      </Button>
      <div className="content gap-5 d-flex flex-column mt-3">

        <div className="form-group row my-4">
          <label htmlFor="productCode" className="col-sm-2 col-form-label">קוד מוצר</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <input
              type="text"
              value={productData.id}
              onChange={(e) => handleInputEdit(e, productData.id, 'name', '')}
              className="form-control"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="categories" className="col-sm-2 col-form-label">קטגוריה</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <FormControl fullWidth>
              <InputLabel id="edit-product-category"></InputLabel>
              <Select
                fullWidth
                labelId="edit-product-category"
                id="edit-product-category-sel"
                value={productData.categories?.replace('|', '') || ""}
                onChange={(e) => {
                  handleInputEdit({ target: { value: e.target.value } }, productData.id, 'categories', '')
                }}
              >
                {mainCategories.map((category) => (
                  <MenuItem value={category.value} key={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label">שם מוצר</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <input
              type="text"
              value={productData.name}
              onChange={(e) => handleInputEdit(e, productData.id, 'name', '')}
              className="form-control"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="images" className="col-sm-2 col-form-label">תמונת מוצר</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <div className='block d-flex gap-3' style={{ marginRight: '25px' }}>
              <h5>
                <label>
                  <input
                    style={{ width: 0, position: "absolute", left: "100%" }}
                    type="file"
                    accept=".jpg, .jpeg, .png, .gif, .svg"
                    multiple
                    onChange={addImages}
                  />
                  <span className='btn btn-primary' style={{ marginRight: '15px' }}>
                    {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
                  </span>
                </label>
              </h5>
              <div>
                <div>
                  {productData.images !== "" && productData.images.split(',').map((imageUrl, index) => {
                    if (imageUrl !== "[]") {
                      return (
                        <div key={imageUrl}>
                          <span>
                            <DeleteIcon onClick={() => deleteImage(index)} />
                          </span>
                          <img src={`${DOMAIN}${imageUrl}`} alt="" />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

{/* Options (אופציות) Section */}
<div className="form-group row my-4">
  <label htmlFor="options" className="col-sm-2 col-form-label">אופציות</label>
  <div className="col-sm-10" style={{ width: '50%' }}>
    <Button
      style={{ backgroundColor: '#154360' }}
      variant="contained"
      onClick={() => addItem('options')}
      className="mb-3"
    >
      {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
    </Button>
    {productData.options?.map((option, index) => (
      <div className="form-group row my-4" key={index}>
        <div className="col-sm-2">
          <IconButton onClick={() => removeItem('options', option.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
        {/* Name Input */}
        <div className="col-sm-4">
          <input
            type="text"
            name="optionName"
            value={option.name}
            onChange={(e) => handleInputEdit(e, option.id, 'options', 'name')}
            className="form-control"
            placeholder="שם"
          />
        </div>
        {/* Price Input */}
        <div className="col-sm-4">
          <input
            type="number"
            name="optionPrice"
            value={option.price}
            onChange={(e) => handleInputEdit(e, option.id, 'options', 'price')}
            className="form-control"
            placeholder="מחיר"
          />
        </div>
      </div>
    ))}
  </div>
</div>
{/* End of Options Section */}

        <h5>טווח מחיר</h5>
        <div className="form-group row">
          <label htmlFor="price" className="col-sm-2 col-form-label">ממחיר</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <input
              value={productData.price ?? ""}
              type="number"
              onChange={(e) => handleInputEdit(e, productData.id, 'price')}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="maxPrice" className="col-sm-2 col-form-label">עד</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <input
              value={productData.maxPrice ?? ""}
              type="number"
              onChange={(e) => handleInputEdit(e, productData.id, 'maxPrice')}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="discountPrice" className="col-sm-2 col-form-label">מחיר מוזל</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <input
              value={productData.discountPrice ?? ""}
              type="number"
              onChange={(e) => handleInputEdit(e, productData.id, 'discountPrice')}
              className="form-control"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="description" className="col-sm-2 col-form-label">תיאור</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <textarea
              value={productData.description ?? ""}
              rows={3}
              onChange={(e) => handleInputEdit(e, productData.id, 'description')}
              className="form-control"
            />
          </div>
        </div>

        <div style={{ display: 'flex', width: '70%', marginTop: '40px' }}>
          <Button
            className='mb-5'
            onClick={postProductUpdate}
            style={{ display: 'block', margin: '0 auto', width: '90px' }}
          >
            {langData[langConfig.userType][langConfig.lang].editProduct.buttons.saveBtn}
          </Button>
        </div>
      </div>
    </Box>
  );
}

export default EditExperience;
