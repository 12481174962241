import React, { useEffect, useState } from 'react'
import Styles from './table.module.css'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'
import { getProviderOrdersById, getUserById } from '../../../config/api'
import { useNavigate } from "react-router-dom";



export default function TableOrdersAdmin({
  data,
  tableLoading,
  heading,
  type,
  orderStatuses,
  toggleOrderStatus,
  langData,
  langConfig

}) {

  const [ordersById, setOrdersById] = useState(data)
  const [wishListId, setWishListId] = useState([])
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState();
  const [userById, setUserById] = useState(null)
  const [ownerById, setOwnerById] = useState(null)

  console.log('fetchOrdersInsideTable', data)
  const fetchOrdersById = async (id) => {
    try {
      const response = await axios.get(getProviderOrdersById(id));
      if (!response.status) return
      const data = response.data;
      console.log('fetchOrdersById', response)
      setOrderDetails(data?.order)
      setOrdersById(data?.orderItemsObjectList)
      setWishListId(id)
    } catch (error) {
      console.error(error);
    }
  };
  const editOrder = (id) => {
    navigate(`/edit-order/${id}`);
  };


  useEffect(() => {
    const fetchBuyerById = async () => {
      try {
        const response = await axios.get(getUserById(orderDetails.buyerUserId));
        if (!response.status) return
        const data = response.data;
        //  console.log('!!!!!!!!!!!!!!', response)
        setUserById(data)
        // setWishListId(id)
      } catch (error) {
        console.error(error);
      }
    };
    const fetchOwnerById = async () => {
      try {
        const response = await axios.get(getUserById(orderDetails.ownerUserId));
        if (!response.status) return
        const data = response.data;
        //  console.log('!!!!!!!!!!!!!!', response)
        setOwnerById(data)
        // setWishListId(id)
      } catch (error) {
        console.error(error);
      }
    };
    fetchBuyerById();
    fetchOwnerById();


  }, [orderDetails])
  console.log('ordersById', ordersById
  )


  return (
    <div className="card mt-5">
      <div className="card-header">
        <h2 className="card-title">
          {langData[langConfig.userType][langConfig.lang].tab4.tabName}
        </h2>
      </div>
      <div className="card-body">
        <div className={Styles.tableResponsive}>
          <table className={`${Styles.table} ${Styles.tableStriped}`}>
            <thead>
              <tr>
                <th className={Styles.horizantal}>
                  מספר הזמנה                </th>
                <th>
                  תאריך רכישה
                </th>
                {/*<th>
                  שם המזמין
  </th>*/}
                <th>
                  שם הספק                </th>
                <th>
                  סכום
                </th>
                <th>
                  {langData[langConfig.userType][langConfig.lang].tab4.table.status}
                </th>
                <th>
                  רכישות                </th>
                <th></th>
              </tr>
            </thead>
            {!tableLoading ? (
              <tbody>
                {console.log("data_orders$$$$$$$$:", data)}
                {data?.length > 0 ? (
                  data.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>{item?.id}</td>
                        <td>
                          {item?.creationDate && (
                            <>
                            {new Date(item.creationDate).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
}).replace(/\//g, '-')}
                              <div>
                                {' '}
                                {new Date(item.creationDate).toLocaleTimeString('he-IL', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                              </div>
                            </>
                          )}
                        </td>                    
                          {/*    <td>
                      {item.buyerName}

                              </td>*/}
                        <td>
                          {item?.providerFullName}
                        </td>
                        <td>
                          ₪{item?.amount}
                        </td>
                        <td>

                          <Form.Select
                            value={item?.status}
                            aria-label=""
                            onChange={(e) => toggleOrderStatus(e, item?.id)}
                          >
                            {orderStatuses && orderStatuses.map(option => (
                              <option value={option}>   <span className="my-purchase-single__price">
                                {option === 'processing' && 'חדשה'}
                                {option === 'pending' && 'בהמתנה'}
                                {option === 'shipped' && 'נשלחה'}
                                {option === 'delivered' && 'התקבלה'}
                                {option ==  'paidProvider' && 'שולם לספק'}

                              </span></option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          <Button onClick={() => fetchOrdersById(item?.id)}>
                            {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.btn}
                          </Button>
                        </td>
                        <td>
                          <Button


                            onClick={() => editOrder(item.id)}

                          >
                            עריכה
                          </Button>
                        </td>

                      </tr>
                      {
                        wishListId == item?.id && (
                          <tr>
                            <td colSpan={12}>
                              <div className={`${Styles.table} ${Styles.tableStriped} table`}>
                                <div className='thead'>
                                  <tr className='tr' style={{ display: 'flex' }}>
                                    <th style={{ flex: '1' }} className={`${Styles.horizantal}`}>
                                      {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.name}
                                    </th>
                                    <th style={{ flex: '1' }} className={`${Styles.horizantal}`}>
                                      {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.userCredentials.title}
                                    </th>
                                    <th style={{ flex: '1' }} className={`${Styles.horizantal}`}>
                                      פרטי משלוח                                    </th>
                                    <th style={{ flex: '1' }} className={`${Styles.horizantal}`}>
                                      {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.price}
                                    </th>
                                    <th style={{ flex: '1' }} className={`${Styles.horizantal}`}>
                                      {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.image}
                                    </th>
                                    <th style={{ flex: '1' }} className={`${Styles.horizantal}`}>
                                      {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.orderId}
                                    </th>
                                    <th style={{ flex: '1' }} className={`${Styles.horizantal}`}>
                                      {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.productId}
                                    </th>
                                    {/*<th style={{ flex: '1' }} className={`${Styles.horizantal}`}>
                                      {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.wishlistItemId}
                        </th>*/}
                                  </tr>
                                </div>
                                {ordersById ? (
                                  <div className='tbody'>
                                    {ordersById.length > 0 ? (
                                      ordersById.map(order => (
                                        <tr className='tr' key={order.id} style={{ display: 'flex' }}>
                                          <td style={{ flex: '1' }} className='td' colSpan={4}>{order.name}</td>
                                          <td style={{ flex: '1' }} className={`td ${Styles.tableUserCredentials}`} colSpan={4}>
                                            <div className={Styles.tableUserCredentialsRow}>
                                              {/*  {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.userCredentials.fullName + ":"}*/}
                                              <div>{`${userById?.firstName} ${userById?.lastName}`}</div></div>

                                            <div className={Styles.tableUserCredentialsRow}>
                                              {/*{langData[langConfig.userType][langConfig.lang].tab4.tableOpen.userCredentials.address +":"}*/}
                                              <div>                                 <div>{userById?.adress.split(',')[0]}</div>

                                                <div> {userById?.adress.split(',')[1]}  </div></div></div>
                                            <div className={Styles.tableUserCredentialsRow}>
                                              {/*   {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.userCredentials.email+":"}*/}
                                              <div>{userById?.email}</div></div>
                                            <div className={Styles.tableUserCredentialsRow}>
                                              {/* {langData[langConfig.userType][langConfig.lang].tab4.tableOpen.userCredentials.shippingTye}
                                              
                                              :<br /> {/*land*/}</div>
                                          </td>
                                          <td style={{ flex: '1' }} className={`td ${Styles.tableUserCredentials}`} colSpan={4}>

                                            {(orderDetails.deliveryReceiver === "mailToMe" || orderDetails.deliveryReceiver === "deliveryToMe") && (
                                              <div>
                                                <div className={Styles.tableUserCredentialsRow}>
                                                  {/* Renders the full name */}
                                                  <div>{`${userById?.firstName} ${userById?.lastName}`}</div>
                                                </div>
                                                <div className={Styles.tableUserCredentialsRow}>
                                                  {/* Renders the address */}
                                                  <div>{userById?.adress.split(',')[0]}</div>
                                                  <div>{userById?.adress.split(',')[1]}</div>
                                                  <div>{userById?.phoneNumber}</div> 
 
                                                </div>
                                              </div>
                                            )}

                                            {(orderDetails.deliveryReceiver === "mailOwner" || orderDetails.deliveryReceiver === "deliveryOwner") && (
                                              <div >
                                                <div className={Styles.tableUserCredentialsRow}>
                                                  {/* Renders the full name */}
                                                  <div>{`${ownerById?.firstName} ${ownerById?.lastName}`}</div>
                                                </div>
                                                <div className={Styles.tableUserCredentialsRow}>
                                                  {/* Renders the address */}
                                                  <div>{ownerById?.adress}</div>
                                                  <div>{ownerById?.phoneNumber}</div>

                                                </div>
                                              </div>
                                            )}


                                            {orderDetails?.deliveryReceiver &&
                                              <div style={{ fontWeight: '600', marginTop: '8px' }}>שיטת משלוח:</div>
                                            }
                                            {orderDetails.deliveryReceiver == "collectionFromBusiness" && "איסוף מבית העסק"}
                                            {orderDetails.deliveryReceiver == "mailOwner" && "דואר רשום"}
                                            {orderDetails.deliveryReceiver == "mailToMe" && "דואר רשום"}
                                            {orderDetails.deliveryReceiver == "deliveryOwner" && "שליח עד הבית"}
                                            {orderDetails.deliveryReceiver == "deliveryToMe" && "שליח עד הבית"}
                                            {orderDetails.deliveryReceiver == "" && "הזמנת מתכנת לא מעודכן"}

                                          </td>

                                          <td style={{ flex: '1' }} className='td' colSpan={4}>₪{order.price}</td>
                                          <td style={{ flex: '1' }} className='td' colSpan={4}>
                                            <img
                                             src={order.image.includes(',') ? `https://server.yyyshopping.com${order.image.replace(',', '')}` :order.image }                                              alt={order.name}
                                              className={Styles.tableProducImage}
                                            />
                                          </td>
                                          <td style={{ flex: '1' }} className='td' colSpan={4}>{order.orderId}</td>
                                          <td style={{ flex: '1' }} className='td' colSpan={4}>{order.productsId}</td>
                                          {/*<td style={{ flex: '1' }} className='td' colSpan={4}>{order.wishlistItemId}</td>*/}
                                        </tr>
                                      ))
                                    ) : (
                                      <div className='tr'>
                                        <div
                                          colSpan={type === "User" ? "12" : "13"}
                                          className="td text-center py-5"
                                        >
                                          אין תוצאות
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className='table'>
                                    <>
                                      <div className='tr'>
                                        <div
                                          colSpan={type === "User" ? "12" : "13"}
                                          className="text-center td"
                                          style={{
                                            height: "200px",
                                          }}
                                        >
                                          <div
                                            className="spinner-border text-primary"
                                            role="status"
                                          ></div>
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      }
                    </>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={type === "User" ? "12" : "13"}
                      className="text-center py-5"
                    >
                      אין תוצאות
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <>
                  <tr>
                    <td
                      colSpan={type === "User" ? "12" : "13"}
                      className="text-center"
                      style={{
                        height: "200px",
                      }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </td>
                  </tr>
                </>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  )
}
